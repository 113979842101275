import React from "react";
import "./Programs.css";
// time to create cards
import { programsData } from "../../data/programsData";
import RightArrow from "../../assets/rightArrow.png";

const Programs = () => {
  return (
    <div className="Programs" id="programs">
      {/* Header */}
      <div className="programs-header">
        <span className="stroke-text">Explore Our</span>
        <span>Programs</span>
        <span className="stroke-text">to shape you</span>
      </div>

      <div className="program-categories">
        {programsData.map((program) => (
          <div className="category">
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now">
              <span>Join Now</span>
              <img src={RightArrow} alt="" />
            </div>
          </div>
        ))}
        {/* Using {} & () in arrow funtion works different, when use {}, then return() will be called. When use () then no return() callback*/}
      </div>
    </div>
    // Now start watching video from (49:00) for Reasons Component...
  );
};

export default Programs;
